import { createStore } from 'vuex';
import colors from './colors'
import axiosInstance from "@/axiosConfig";
import router from './router';

export default createStore({
  state: {
    isAuthenticated: false,
    idToken:         null,
    userEmail:       null,
    activeInterval:  null,
    active:          [],
    loading:         false
  },

  mutations: {
    setAuthentication(state, { isAuthenticated, idToken, userEmail }) {
      state.isAuthenticated = isAuthenticated;
      state.idToken = idToken;
      state.userEmail = userEmail;
    },

    clearAuthentication(state) {
      state.isAuthenticated = false;
      state.idToken = null;
      state.userEmail = null;
    },

    setActive(state, active) {
      state.active = active
    },

    setActivePoll(state, interval) {
      if (state.activeInterval) clearInterval(state.activeInterval)

      state.activeInterval = interval
    },

    setLoading(state, value) {
      state.loading = value
    }
  },

  actions: {
    signOut({ commit }) {
      // Clear session storage and Vuex state
      sessionStorage.removeItem('idToken');
      sessionStorage.removeItem('userEmail');
      commit('clearAuthentication');
    },

    checkAuthentication({ commit }) {
      // Check if user is already authenticated (on page reload)
      const idToken = sessionStorage.getItem('idToken');
      const userEmail = sessionStorage.getItem('userEmail');
      if (idToken && userEmail) {
        commit('setAuthentication', { isAuthenticated: true, idToken: idToken, userEmail: userEmail });
      }
    },

    async generateSummary({ state }, { playerCount, playerLevel, biome }) {
      axiosInstance
          .post(`summaries`, { playerCount, playerLevel, biome }, { headers: { Authorization: `Bearer ${state.idToken}` } })
          .then((response) => {
            console.log(response)
            const { encounterId } = response.data
            router.push({ path: `/encounters/${encounterId}`, params: { id: encounterId }})

          })
          .catch((error) => {
            console.log(error)
          })
    }
  },

  getters: {
    isAuthenticated: state => state.isAuthenticated,
    getIdToken:      state => state.idToken,
    getUserEmail:    state => state.userEmail,
    active:          state => state.active,
    loading:         state => state.loading,
    colors:          () => colors
  },
});

<template>
  <div class="img-wrapper" style="position: relative">
    <div v-if="imgSrc" style="position: relative">
      <img class='img' :src="imgSrc" />
      <div class="reload-btn btn btn-secondary btn-small" @click="reloadImage">Try Again</div>
    </div>
    <div v-else
         v-loading="true"
         class="image-placeholder"
         :style="{ height: placeholderHeight + 'px' }" />
  </div>
</template>

<script>
import axiosInstance from "@/axiosConfig";

export default {
  name: 'Image',

  props: ['imageId', 'placeholderWidth', 'placeholderHeight'],

  data() {
    return {
      imgSrc: null
    }
  },

  mounted() {
    this.loadImage()
  },

  methods: {
    reloadImage() {
      this.imgSrc = null
      axiosInstance
          .post(`/map/`, { id: this.imageId, reload: true },{ headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            this.imgSrc = response.data
          })
          .catch((error) => {
            console.log(error)
          })
    },
    loadImage() {
      axiosInstance
          .post(`/map/`, { id: this.imageId },{ headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            this.imgSrc = response.data
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
}
</script>

<style>
.img-wrapper {
  object-fit: contain;
}

.img{
  width: 100%;
}

.image-placeholder {
  background: #847b67;
  width: 100%;
}

.reload-btn{
  right: 10px;
  top: 10px;
  position: absolute;
}
</style>

<template>
  <div v-loading="loading">
      <GenerateNewEncounter v-show="showNewEncounters" class="form-section" :items="items" @switch="showNewEncounters=!showNewEncounters" />
      <EncounterTable v-show="!showNewEncounters" :encounters='encounters' @switch="showNewEncounters=!showNewEncounters" />
  </div>
</template>
<script>

import GenerateNewEncounter from '../components/GenerateNewEncounter.vue'
import EncounterTable from "@/components/EncounterTable.vue";
import axiosInstance from "@/axiosConfig";

export default {
  name: 'Dashboard',
  components: {
    EncounterTable,
    GenerateNewEncounter,
  },

  data() {
    return {
      showNewEncounters: true,
      loading: false,
      encounters: []
    }
  },

  mounted() {
    this.getEncounters()
  },

  methods: {
    getEncounters() {
      this.loading = true
      axiosInstance
          .get(`/encounter`, { headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            this.encounters = response.data.encounter
            console.log("Encounters", this.encounters)
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => this.loading = false)
    }
  }
}
</script>

<style>

</style>

<template>
  <div v-loading="loading">
    <SelectSummary v-if="showSelectSummary" :encounter="encounter" @updateEncounter="updateEncounter" />
    <EncounterDetails v-if="showDetails" :encounter="encounter" />
  </div>
</template>

<script>

import axiosInstance from "@/axiosConfig";
import SelectSummary from "@/components/SelectSummary.vue"
import EncounterDetails from "@/components/EncounterDetails.vue"

export default {
  name: 'EncountersPage',

  components: { SelectSummary, EncounterDetails },

  data() {
    return{
      encounter: null,
      encounterStatus: null
    }
  },

  async mounted() {
    this.getEncounter(this.$route.params.id)
  },

  methods: {
    updateEncounter(encounter) {
      console.log('Updating status', encounter, encounter.status)
      this.encounterStatus = encounter.status
    },

    getEncounter(encounterId) {
      this.loading = true
      axiosInstance
          .get(`/encounters/${encounterId}`, { headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            const encounter = response.data
            console.log("Got Encounter", encounter)
            this.encounter = encounter
            this.encounterStatus = encounter.status
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => this.loading = false)
    }
  },

  computed: {
    showSelectSummary() {
      return this.encounterStatus === "PENDING_SUMMARIES" || this.encounterStatus === 'WAITING_SUMMARY_SELECTION'
    },
    showDetails() {
      return this.encounterStatus === 'GENERATING_DETAILS' || this.encounterStatus === 'COMPLETE'
    }
  }
}
</script>

<style>
  .summary-section {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding: 1.5rem;
  }

  .summary {
    height: 100%;
    color: var(--bg-color);
    box-shadow: 0 10px 34px -15px rgb(108, 106, 106);
    background-color: var(--card-color);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .summary:hover {
    background-color: #c0b08d;
    box-shadow: 10px 20px 60px -15px black;
  }
</style>

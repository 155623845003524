<template>
  <div>

    <div class="row justify-content-center">
      <div v-loading="loading" class="col-md-12 card">
        <div>
          <div class="wrap d-md-flex">
            <img v-if="imgId == 1" class="img" src="../images/login1.png">
            <img v-if="imgId == 2" class="img" src="../images/login2.png">
            <img v-if="imgId == 3" class="img" src="../images/login3.png">
            <img v-if="imgId == 4" class="img" src="../images/login4.png">
            <img v-if="imgId == 5" class="img" src="../images/login5.png">
            <img v-if="imgId == 6" class="img" src="../images/login6.png">
            <img v-if="imgId == 7" class="img" src="../images/login7.png">
            <img v-if="imgId == 8" class="img" src="../images/login8.png">
            <div class="login-wrap">
              <div>
                <div class="d-flex">

                  <div class="w-100">
                    <h4 class="mb-2 heading-section">
                      Random Encounter Generator
                      <hr>
                    </h4>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label class="label" for="name">Username</label>
                  <input id="userId" v-model="formData.id" type="text" class="form-control" placeholder="Username"
                    required="">
                </div>
                <div class="form-group mb-3">
                  <label class="label" for="password">Password</label>
                  <input id="userPass" v-model="formData.password" type="password" class="form-control"
                    placeholder="Password" required="">
                </div>
                <div class="form-group mb-3">
                  <span v-if="errorMsg" class="error text-danger">{{ errorMsg }}</span>
                </div>
                <div class="form-group">
                  <button type="submit" class="form-control btn btn-primary rounded submit px-3" @click="signIn">
                    Sign In
                  </button>
                </div>
              </div>

              <!-- <div class="form-group d-md-flex">
              <div class="w-50 text-left">
                <label class="checkbox-wrap checkbox-primary mb-0">Remember Me
                  <input type="checkbox" checked="">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="w-50 text-md-right">
                <a href="#">Forgot Password</a>
              </div>
            </div> 
            <p class="text-center">Not a member? <a data-toggle="tab" href="#signup">Sign Up</a></p>-->
            </div>
          </div>
        </div>
      </div>
      <el-dialog v-model="showResetPassword" title="Reset Password For Account" width="30%">
        <form v-loading="resetLoading" @submit.prevent="resetPass">
          <div>
            <label for="password">New Password </label>
            <el-input v-model="resetFormData.password" type="password" placeholder="Please input password"
              show-password />
          </div>
          <div class="footer dialog-footer mt-4">
            <el-button @click="showResetPassword = false">
              Cancel
            </el-button>
            <el-button type="primary" @click="resetPass">
              Confirm
            </el-button>
          </div>
        </form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../axiosConfig'

export default {
  name: 'Login',
  data() {
    return {
      formData: {
        id: '',
        password: '',
      },
      resetFormData: {
        password: '',
        id: '',
        session: ''
      },
      imgId: null,
      errorMsg: '',
      response: '',
      loading: false,
      signupLoading: false,
      resetLoading: false,
      showResetPassword: false
    }
  },
  mounted(){
    this.imgId = this.randomIntFromInterval(1, 8)
  },
  methods: {
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    resetPass() {
      this.resetLoading = true
      axiosInstance
        .post('reset', this.resetFormData)
        .then((response) => {
          console.log(response)
          this.response = response
          if (response.data.AuthenticationResult) {
            const access = response.data.AuthenticationResult.IdToken
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access}`
            // this.$emit('authenticated', access)
          } else if (response.data.ChallengeName) {
            console.log('here')
          }
        })
        .catch((error) => {
          console.log(error.response)
          this.errorMsg = error?.response?.data?.error ? error?.response?.data?.error : 'Error posting data'
        })
        .finally(() => this.resetLoading = false)
    },

    async signIn() {
      this.loading = true
      this.errorMsg = ''
      try {
        const response = await axiosInstance.post('login', this.formData)
        if (response.data.AuthenticationResult) {
          const { IdToken, /* Other tokens if needed */ } = response.data.AuthenticationResult
          // Save tokens and user details in session storage
          sessionStorage.setItem('idToken', IdToken)
          sessionStorage.setItem('userEmail', this.formData.id)
          this.$store.commit('setAuthentication', { isAuthenticated: true, idToken: IdToken, userEmail: this.formData.id })
          this.$router.push('/dashboard');
        } else if (response.data.ChallengeName) {
          this.resetFormData.id = this.formData.id
          this.resetFormData.session = response.data.Session
          this.showResetPassword = true
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMsg = error?.response?.data?.error ? error?.response?.data?.error : 'Error posting data'
        } else {
          console.error(error)
          throw error
        }
      } finally {
        this.loading = false // Ensure to stop the loading state
      }
    },
  }
}
</script>
<style scoped>
.card {
  background-color: var(--card-color);
  box-shadow: 0 10px 34px -15px black;
  overflow: hidden;
  padding: 0px;
  border-radius: 20px;
  border: 0;
  width: 100%;
}

.wrap {
  overflow: hidden;
  justify-content: start;
}


.img {
  width: 300px;
}

/* @media (max-width: 991.98px) {

  .wrap .img {
    height: 250px;
  }
}

@media (max-width: 767.98px) {
  .wrap .img {
    height: 250px;
  }
} */

.login-wrap {
  width: 45%;
  margin: 2rem;
  flex: 1;
  justify-content: space-around;
  color: var(--bg-color)
}

.form-group {
  position: relative;
}

.form-group .label {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.form-group a {
  color: gray;
}

.form-control {
  height: 48px;
  background: #fff;
  color: #000;
  font-size: 16px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e3b04b;
}



.col-lg-10 {
  width: 55%;
}

:deep() .el-dialog__body {
  padding: 10px 30px 25px;
}
</style>
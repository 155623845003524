<template>
  <div class="question-wrapper">
    <h6 class="question" @click="hidden = ! hidden">
      {{ question.question }}
    </h6>
    <p v-if='!hidden' class="answer"> {{ question.answer }}</p>
  </div>
</template>

<script>

export default {
  name: 'QuestionSection',

  props: ['question'],

  data() {
    return {
      hidden:   true,
    }
  }
}
</script>

<style>

.question {
  font-weight: bold;
  color: var(--secondary-focus);
}

.question:hover {
  color: var(--secondary);
  cursor: pointer;
  text-decoration: underline;
}

.question-wrapper{
  margin-bottom: 2rem;
}

.answer {

}
</style>

import axios from 'axios';
// import router from './router'; // Import your Vue Router instance
// import store from './store'; // Import your Vuex store

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT, // Set your API base URL
  // Other Axios configurations...
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.error('ERROR', error, JSON.stringify(error))
    if (!error.response) {
      // Network error (no response received)
      this.$message("An Error occured")
      // store.commit('clearAuthentication'); // Clear authentication data in Vuex store
      // router.push('/login'); // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

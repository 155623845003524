<template>
  <div>
    <LoadingScreen v-if='intialLoading' />
    <section v-if="!intialLoading && details" style="flex-direction: column;"  class="w-100">
      <div class="encounter-detail-card padded-card">
        <a style='float: right' href="/" class="btn btn-secondary btn-small"> Back </a>
        <h4 class='secondary-text' style="font-weight: bolder;"> {{ encounter.selectedSummary }}</h4>
        <p> {{ encounter.location }}</p>
        <hr>
        {{ details.summary }}
      </div>

      <div class="encounter-detail-card">
        <Image style='width:100%' v-if="details.locationImageId" :imageId="details.locationImageId"
          placeholderHeight="1024"
        />
        <div v-else slot="placeholder" class="image-slot">
          Loading Image<span class="dot">...</span>
        </div>
        <div class="padded-card">
          {{ details.location }}
        </div>

      </div>

      <div class="update">
        <el-input v-model="updateText"></el-input>
        <div class="btn btn-info btn-small" @click="sendUpdate">Update</div>
      </div>
      <div class="enemies">
        <enemy v-for="npc in allCharacters" :key="npc.name"
               :npc="npc"/>
      </div>

      <questions
          v-if="details && details.potentialQuestions && details.potentialQuestions.length > 0"
          :details="details"
          :encounter="encounter" />
    </section>
  </div>
</template>
<script>

import axiosInstance from "@/axiosConfig";
import Enemy from './Enemy.vue';
import Image from './Image.vue'
import LoadingScreen from "@/components/LoadingScreen.vue";
import Questions from "@/components/Questions.vue"

export default {
  name: 'EncountersPage',

  components: {Questions, LoadingScreen, Enemy, Image },

  props: ['encounter'],

  data() {
    return {
      intialLoading: true,
      details:       {},
      questions:     [],
      updateText: ''
    }
  },

  computed: {
    allCharacters() {
      if (!this.details) return []
      const enemies = this.details.enemies || []
      const npcs = this.details.nonPlayerCharacters || []
      return enemies.concat(npcs)
    }
  },
  mounted() {
    console.log("Details Mounted")
    this.getDetails()
  },

  methods: {
    sendUpdate() {
      this.intialLoading = true
      axiosInstance
          .patch(`/details/${this.encounter.id}`, {updateMessage: this.updateText}, { headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            const { status, output, message } = response.data
            console.log(status, output, message)

            this.details = output
            this.getDetails()
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getDetails() {
      this.intialLoading = true
      axiosInstance
          .get(`/details/${this.encounter.id}`, { headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            const { status, output, message } = response.data
            console.log(status, output, message)

            this.details = output

            if (status === 'pending' || status === 'error') {
              setTimeout(() => this.getDetails(), 5000)
            } else {
              this.intialLoading = false
            }
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
}
</script>

<style>
.summary-section {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 1.5rem;
}

.summary {
  height: 100%;
  color: var(--bg-color);
  box-shadow: 0 10px 34px -15px rgb(108, 106, 106);
  background-color: var(--card-color);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.summary:hover {
  background-color: #c0b08d;
  box-shadow: 10px 20px 60px -15px black;
}
</style>

<template>
  <div class="enemy-block">
    <Image class="creature-img" v-if="npc.imageId" :imageId="npc.imageId"
      placeholderHeight="500" />
    <div style="padding: 15px;">
      <div v-if="npc.numberEncountered && parseInt(npc.numberEncountered) > 1">
        <h4 class="number-encounter">X {{ npc.numberEncountered }}</h4>
      </div>
      <h4 style="font-weight: bolder;">{{ npc.name }}</h4>
      <h6> {{ npc.creatureSize }} {{ npc.race }}</h6>
      <h6 style="margin-top: 1rem;">{{ npc.personality }}</h6>
      <hr>

      <div class='label-wrap'>
        <span class="label-name"> Armor Class: </span>
        <span class="label-description">{{ npc.stats.ac }} </span>
      </div>
      <div class='label-wrap'>
        <span class="label-name"> Hit Points: </span>
        <span class="label-description">{{ npc.stats.hp }} </span>
      </div>
      <div class='label-wrap'>
        <span class="label-name"> Speed: </span>
        <span class="label-description">{{ npc.stats.speed }} </span>
      </div>

      <hr>

      <div v-if="npc.stats">

        <h5>Attributes:</h5>
        <div class="attribute-block">
          <div class="attribute-element">
            <div>STR</div>
            <el-tag type="success" effect="dark">{{ npc.stats.str }}</el-tag>
          </div>
          <div class="attribute-element">
            <div>DEX</div>
            <el-tag type="success" effect="dark">{{ npc.stats.dex }}</el-tag>
          </div>
          <div class="attribute-element">
            <div>CON</div>
            <el-tag type="success" effect="dark">{{ npc.stats.con }}</el-tag>
          </div>
          <div class="attribute-element">
            <div>INT</div>
            <el-tag type="success" effect="dark">{{ npc.stats.int }}</el-tag>
          </div>
          <div class="attribute-element">
            <div>WIS</div>
            <el-tag type="success" effect="dark">{{ npc.stats.wis }}</el-tag>
          </div>
          <div class="attribute-element">
            <div>CHA</div>
            <el-tag type="success" effect="dark">{{ npc.stats.cha }}</el-tag>
          </div>
        </div>
      </div>
      <hr>
      <div v-if="npc?.stats?.saves && npc.stats.saves.length > 0" class='label-wrap'>
        <span class="label-name"> Saving Throws: </span>
        <span class="label-description">{{ npc.stats.saves }} </span>
      </div>
      <div v-if="npc?.stats?.skills && npc.stats.skills.length > 0" class='label-wrap'>
        <span class="label-name"> Skills: </span>
        <span class="label-description">{{ npc.stats.skills }} </span>
      </div>
      <div v-if="npc?.stats?.senses && npc.stats.senses.length > 0" class='label-wrap'>
        <span class="label-name"> Senses: </span>
        <span class="label-description">{{ npc.stats.senses }} </span>
      </div>
      <div v-if="npc?.stats?.languages && npc.stats.languages.length > 0" class='label-wrap'>
        <span class="label-name"> Languages: </span>
        <span class="label-description">{{ npc.stats.languages }} </span>
      </div>
      <div class='label-wrap'>
        <span class="label-name"> Challenge: </span>
        <span class="label-description">{{ npc.stats.cr }} </span>
      </div>

      <hr>
      <h5>Actions:</h5>
      <div class='label-wrap' v-for="action in npc.stats.actions" :key="action.actionName">
        <span class="label-name"> {{ action.actionName }}:</span>

        <span class="label-description">{{ action.actionDetail }} </span>
      </div>

      <hr>
      <div>
        <div v-if="npc.stats.damageImmunity" class='label-wrap'>
          <span class="label-name"> Damage Immunity:  </span>
          <span class="label-description">{{ npc.stats.damageImmunity }} </span>
        </div>
        <div v-if="npc.stats.damageResistance" class='label-wrap'>
          <span class="label-name"> Damage Resistance:  </span>
          <span class="label-description">{{ npc.stats.damageResistance }} </span>
        </div>
        <div v-if="npc.stats.damageVulnerability" class='label-wrap'>
          <span class="label-name"> Damage Vulnerability:  </span>
          <span class="label-description">{{ npc.stats.damageVulnerability }} </span>
        </div>
      </div>

      <h5>Keywords:
        <el-tag type='danger' effect='plain' v-for="kw in npc.keywords" :key="kw"> {{ kw }}</el-tag>
      </h5>
    </div>


  </div>
</template>

<script>
import Image from "./Image.vue"

export default {
  props: ['npc'],

  components: { Image }
}
</script>

<style>
.enemy-block {
  background-color: var(--card-color);
  color: var(--bg-color);
  padding: 0px;
  border-radius: 15px;
  margin: 10px;
  max-width: 500px;
  overflow: clip;
}

.creature-img {
  max-width: 500px;
}

.attribute-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-wrap {
  display: block;
}

.label-name {
  margin-right: 1rem;
  font-weight: bolder;
}

.number-encounter{
  font-weight: bolder;
  color: var(--secondary-focus);
  float: right
}

.label-description {

}
</style>

import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'
import App from './App.vue'


const app = createApp(App)
app.use(ElementPlus)
app.use(store)

// Check authentication status on page load
const idToken = sessionStorage.getItem('idToken');
const userEmail = sessionStorage.getItem('userEmail');
if (idToken && userEmail) {
  store.commit('setAuthentication', { isAuthenticated: true, idToken, userEmail });
}

app.use(router)

app.mount('#app')

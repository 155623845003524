<template>
  <div>
    <section  class="summary-section w-100">
      <LoadingScreen v-if='intialLoading' />
      <div v-else v-loading="loading" class="summary" v-for="summary in summaries" :key="summary.encounterName">
        <h4>{{ summary.encounterName }}</h4>
        <hr>
        <div style="flex:1;margin-bottom: 1.5rem;">{{ summary.encounterSummary }}</div>
        <div class='btn btn-primary' @click="generateDetails(summary)" type="primary" icon="el-icon-circle-plus-outline">
          Let's Explore This Idea
        </div>

      </div>
    </section>
  </div>
</template>
<script>

import axiosInstance from "@/axiosConfig";
import LoadingScreen from "./LoadingScreen.vue";

// import router from "@/router";

export default {
  name: 'EncountersPage',

  components: { LoadingScreen },

  props: ['encounter'],

  data() {
    return {
      intialLoading: true,
      loading: false,
      summaries: []
    }
  },

  mounted() {
    console.log("Select Summary Mounted")
    this.getSummaries()
  },

  methods: {
    generateDetails(summary) {
      this.loading = true
      axiosInstance
          .post(`details/${this.encounter.id}`, { selectedSummary: summary.encounterName }, { headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            console.log(response)
            this.$emit('updateEncounter', response.data)
            // const { encounterId } = response.data
            // router.push({ path: `/encounters/${encounterId}`, params: { id: encounterId }})
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getSummaries() {
      axiosInstance
          .get(`/summaries/${this.encounter.id}`, { headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            const { status, output, message } = response.data
            console.log(status, output, message)

            this.summaries = output

            if (status === 'pending') {
              setTimeout(() => this.getSummaries(), 5000)
            } else {
              this.intialLoading = false
            }
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
}
</script>

<style>
.summary-section {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 1.5rem;
}

.summary {
  height: 100%;
  color: var(--bg-color);
  box-shadow: 0 10px 34px -15px rgb(108, 106, 106);
  background-color: var(--card-color);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.summary:hover {
  background-color: #c0b08d;
  box-shadow: 10px 20px 60px -15px black;
}
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import store from './store'
import Login from './pages/Login.vue'
import Dashboard from './pages/Dashboard.vue'
import Encounters from './pages/Encounter.vue'

const routes = [
  { path: '/', redirect: { name: 'dashboard' } },
  { path: '/login', name: 'login', component: Login, meta: { title: 'Login' } },
  { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { requiresAuth: true, title: 'Random Encounter Generator' } },
  { path: '/encounters/:id', name: 'encounters', component: Encounters, meta: { requiresAuth: true, title: 'Encounter' } },
]

const authenticationGuard = (to, from, next) => {
  document.title = `${to.meta.title} | Environment Manager`
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login')
  } else {
    next()
  }
}

const router = createRouter({
  history: createWebHashHistory(), // Use createWebHistory
  routes
})

router.beforeEach(authenticationGuard)

export default router

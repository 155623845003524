const locations = [
    "A desolate graveyard under a blood moon",
    "A mystical cavern filled with glowing crystals",
    "A forgotten village overrun by plant creatures",
    "A haunted shipwreck on a cursed shore",
    "An abandoned wizard's tower enveloped in mist",
    "A sunken temple beneath a crystal-clear lake",
    "A ruined cathedral haunted by ghostly whispers",
    "A celestial observatory atop a floating island",
    "A time-worn coliseum in the heart of the desert",
    "A bustling marketplace in the shadow of a volcano",
    "A frozen wasteland with ancient ice elemental tombs",
    "A labyrinthine cave network infested with kobolds",
    "A cursed mine where the undead toil endlessly",
    "A spectral library guarded by animated books",
    "A scorched battlefield haunted by fallen soldiers",
    "A hidden oasis in the midst of a vast desert",
    "An otherworldly garden with sentient plants",
    "A twisted maze filled with illusionary creatures",
    "A decrepit asylum housing eldritch horrors",
    "A mystical portal leading to a mirror dimension",
    "A moonlit glade inhabited by mischievous fey",
    "A spectral ship sailing through the ethereal plane",
    "A subterranean city ruled by duergar overlords",
    "An ancient druidic circle in a sacred grove",
    "A floating fortress held aloft by arcane magic",
    "A cursed village plagued by a never-ending storm",
    "A petrified forest with stone creature guardians",
    "An abandoned alchemical laboratory filled with traps",
    "A hidden grotto guarded by territorial merfolk",
    "A massive tree with an entire ecosystem on its branches",
    "A mysterious monolith with ancient inscriptions",
    "A feywild glade with dancing lights and playful sprites",
    "A forgotten battlefield where magic warps reality",
    "A hidden entrance to the Underdark in a bustling city",
    "A crystalline cave inhabited by elemental beings",
    "A mountain pass haunted by vengeful spirits",
    "A cursed well that grants wishes with dire consequences",
    "An ancient colossus half-buried in the sand",
    "A floating city in the clouds governed by air elementals",
    "A volcanic forge controlled by fire giants",
    "A forgotten temple guarded by animated statues",
    "A spectral bridge leading to the realm of the dead",
    "An eerie swamp shrouded in perpetual fog",
    "A celestial palace guarded by celestial beings",
    "A corrupted garden overrun by demonic flora",
    "A time-frozen battlefield from an ancient war",
    "A hidden cavern filled with illusions and mirages",
    "A cursed carnival that appears only at midnight",
    "An arcane laboratory with unstable magical experiments",
    "A colossal tree inhabited by arboreal creatures",
    "A spectral coliseum where ancient gladiators still fight",
    "A sunken city beneath the waves ruled by sahuagin",
    "A crystallized cavern with gemstone golem guardians",
    "An ethereal bridge connecting two distant realms",
    "A jungle temple with a shifting labyrinth within",
    "A forgotten library with sentient, talking books",
    "A twisted forest where trees come to life",
    "A shadowy alleyway in the heart of a bustling city",
    "A floating island with gravity-defying landscapes",
    "An abandoned mine haunted by the spirits of miners",
    "A magical bazaar in a demiplane accessible through mirrors",
    "A celestial battlefield with angelic and demonic remnants",
    "A cursed glade with malevolent woodland creatures",
    "An ancient ziggurat with a portal to the Elemental Plane",
    "A gargantuan hollow tree with its own ecosystem",
    "A subterranean lake guarded by water elemental spirits",
    "A crystalline labyrinth with shifting pathways",
    "An abandoned wizard's tower surrounded by illusions",
    "A spectral forest where time flows differently",
    "A floating platform in the astral sea with arcane anomalies",
    "A cursed temple with ever-changing corridors",
    "A forgotten mine containing precious gemstone deposits",
    "An elemental forge where golems are crafted",
    "A twisted spire that distorts perception",
    "A sunken city inhabited by aquatic undead",
    "A spectral circus with ghostly performers",
    "An otherworldly desert with shifting dunes",
    "A submerged cavern with bioluminescent flora",
    "A shadowy grove where nightmares come to life",
    "A frozen lake inhabited by ice elemental spirits",
    "A hidden canyon with ancient petroglyphs",
    "An abandoned laboratory filled with animated constructs",
    "A spectral market where lost souls trade memories",
    "A cursed graveyard where undead rise with each new moon",
    "An ancient obelisk with the power to manipulate time",
    "A floating fortress protected by magical barriers",
    "A twisted swamp with sentient, carnivorous plants",
    "A celestial garden where starlight manifests as creatures",
    "A sunken cathedral guarded by aquatic serpents",
    "An ethereal labyrinth where reality itself is a puzzle",
    "A corrupted temple with demonic sigils and traps",
    "A forgotten outpost in the Astral Plane",
    "A petrified jungle with stone animal guardians",
    "An abandoned prison haunted by vengeful spirits",
    "A spectral village where echoes of the past linger",
    "A feywild glade with mirrored pools reflecting other realms",
    "A hidden valley with a portal to the Plane of Shadows",
    "An elemental storm with swirling air, fire, and water",
    "A crystalline spire with strange gravitational anomalies",
    "A spectral desert where mirages come to life",
    "A cursed castle in the clouds ruled by an ancient vampire",
    "An otherworldly forest where trees have shifting faces",
    "A submerged ruin with ancient underwater puzzles",
    "A celestial bridge connecting two distant realms",
    "A sunken library filled with enchanted books",
    "A twisted canyon with echoes of haunting laughter",
    "A spectral bazaar where lost items from different planes gather",
    "A hidden vault containing artifacts from forgotten civilizations",
    "A road through an old growth forest",
    "A long-abandoned graveyard",
    "A bustling market square in a small town",
    "An overgrown orchard with ripe fruit",
    "A dilapidated farmhouse with a creaky windmill",
    "A quiet fishing village with a rocky shore",
    "A well-traveled trade route between two cities",
    "A cozy inn known for its hearty stews",
    "A simple stone bridge crossing a lazy river",
    "A small hamlet with friendly locals",
    "A scenic hill with a solitary oak tree",
    "A narrow alley in a crowded city",
    "A peaceful meadow with grazing livestock",
    "A stone quarry with workers extracting raw materials",
    "A quaint bakery known for its delicious pastries",
    "A community garden tended by the townspeople",
    "A dusty crossroads with a weathered signpost",
    "A roadside tavern popular among travelers",
    "A serene lakeside with crystal-clear water",
    "A modest shrine dedicated to a local deity",
    "A family-owned blacksmith shop",
    "A winding path through rolling hills",
    "A small chapel atop a grassy knoll",
    "A lively town square with street performers",
    "A modest schoolhouse with eager students",
    "A historic lighthouse overlooking the coastline",
    "A well-maintained public library",
    "A quaint cottage with a colorful flower garden",
    "A well-used trade road frequented by caravans",
    "A peaceful dock with fishing boats",
    "A riverside picnic area popular with locals",
    "A busy crossroads with market stalls",
    "A scenic viewpoint with a breathtaking panorama",
    "A lively tavern with a friendly atmosphere",
    "A simple wooden bridge over a bubbling creek",
    "A cozy bookstore with a knowledgeable owner",
    "A small farm with fields of crops",
    "A charming village square with a bubbling fountain",
    "A quiet path through a sun-dappled forest",
    "A well-worn path leading to a hidden waterfall",
    "A local apothecary known for herbal remedies",
    "A quaint bakery with the scent of fresh bread",
    "A peaceful graveyard with weathered headstones",
    "A busy market street with colorful awnings",
    "A small dairy farm with grazing cows",
    "A cozy cottage with smoke rising from the chimney",
    "A roadside shrine dedicated to a forgotten god",
    "A historic windmill with rotating blades",
    "A lively town square with street vendors",
    "A well-tended vineyard with rows of grapevines",
    "A riverside park with families enjoying picnics",
    "A scenic trail through a blooming wildflower field",
    "A peaceful village with thatched-roof cottages",
    "A bustling harbor with ships unloading cargo",
    "A well-kept garden in the heart of the city",
    "A simple wooden bridge crossing a tranquil stream",
    "A quiet mill on the edge of a river",
    "A cozy inn known for its warm hearth",
    "A roadside stand selling fresh produce",
    "A small chapel with a peaceful cemetery",
    "A lively market square with colorful tents",
    "A charming bakery famous for its pies",
    "A quaint fishing dock with small boats",
    "A peaceful grove with ancient, moss-covered stones",
    "A narrow alley lined with quaint shops",
    "A bustling town square with a central fountain",
    "A well-maintained stable with horses and wagons",
    "A quiet trail leading to a hidden cave",
    "A scenic bluff overlooking the sea",
    "A local pub with a friendly bartender",
    "A serene pond surrounded by weeping willows",
    "A busy intersection with street performers",
    "A rustic watermill by the side of a river",
    "A charming tea house with a tranquil garden",
    "A well-tended orchard with apple trees in bloom",
    "A roadside shrine visited by pilgrims"
]

export default locations

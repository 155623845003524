<template>
  <el-menu
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    :router="true"
    :default-active="$route.path"
  >
    <el-menu-item route="dashboard">
      <img
        style="width: 50px"
        src="../images/logo.png"
        alt="Element logo"
      >
    </el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="2" route="dashboard">
      {{ getUserMailId }}
    </el-menu-item>
    <el-menu-item
      @click="logout"
    >
      Logout
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    getUserMailId() {
      return this.$store.getters.getUserEmail;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('signOut');
      this.$router.push('/login');
    }
  }
}
</script>

<style>
.flex-grow {
  flex-grow: 1;
}
</style>

<template>
  <div :style="cssVars">
    <div class="adventure-image">
      <img v-if='imgId == 1' class='adventure-image-img' src="./images/adventure1.png">
      <img v-if='imgId == 2' class='adventure-image-img' src="./images/adventure3.png">
      <img v-if='imgId == 3' class='adventure-image-img' src="./images/adventure4.png">
      <img v-if='imgId == 4' class='adventure-image-img' src="./images/adventure5.png">
      <img v-if='imgId == 5' class='adventure-image-img' src="./images/adventure5.png">
    </div>

    <Header v-if="isAuthenticated" />
  <div class="container custom-bg">

    <div
      class="row"
      :class="isAuthenticated === true ? 'inner-page' : 'login-page'"
    >
      <div class="col-md-12">
        <router-view />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import colors from './colors'
import Header from './components/Header.vue'
export default {
  name: 'App',
  data() {
    return{
      imgId: null,
      colors
    }
  },

  mounted() {
    this.imgId = this.randomIntFromInterval(1, 5)
  },

  methods: {
    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  },

  components: {
    Header
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    cssVars(){
      return {
        '--bg-color':        colors.body,
        '--card-color':      colors.card,
        '--primary':         colors.primary,
        '--primary-focus':   colors.primaryFocus,
        '--secondary':       colors.secondary,
        '--secondary-focus': colors.secondaryFocus
      }
    }
  }
}
</script>
<style>
body {
    font-family: "Lato", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    background: #403832;
    color: #fff;
}

.pull-right{
  float: right;
}

.custom-bg{
  width: fit-content;
}

.login-page {
  align-items: center;
  height: 100vh;
}

.inner-page {
  margin-top: 2rem;
}

.btn {
  cursor: pointer;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 15px;
  padding: 10px 20px;
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
}

.btn.btn-primary {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: #fff !important;
}

.btn.btn-primary:hover {
  border: 1px solid var(--primary-focus);
  background: var(--primary-focus);
  color: lightgrey;
}

.btn.btn-secondary {
  background: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
  color: #fff !important;
}

.btn.btn-secondary:hover {
  border: 1px solid var(--secondary-focus);
  background: var(--secondary-focus);
  color: lightgrey;
}

.btn.btn-small{
  font-size: 12px;
  padding: 5px 10px;
}


.adventure-image {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  box-shadow: 0 10px 34px -15px black;
}

.adventure-image-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

</style>

<template>
  <div v-loading='loading' class="encounter-detail-card question-card">
    <h4 class='secondary-text' style="font-weight: bolder;"> Questions you may have </h4>
    <hr>
    <question v-for="question in questions" :key="question.question" :question="question" />
  </div>
</template>

<script>

import axiosInstance from "@/axiosConfig";
import Question from "./Question";

export default {
  name: 'QuestionsSection',

  components: { Question },

  props: ['encounter', 'details'],

  data() {
    return {
      loading:   true,
      questions: []
    }
  },

  mounted() {
    console.log("questions mounted")
    this.getQuestion()
  },

  methods: {
    getQuestion() {
      const questionId = this.details.questionId
      this.loading = true
      axiosInstance
          .get(`/encounter/${this.encounter.id}/questions/${questionId}`,{ headers: { Authorization: `Bearer ${this.$store.getters.getIdToken}` } })
          .then((response) => {
            const { status, output, message } = response.data
            console.log(status, output, message)

            this.questions = output

            if (status === 'pending' || status === 'error') {
              setTimeout(() => this.getQuestion(questionId), 5000)
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
}
</script>

<style>
  .question-card {
    padding: 2rem;
  }

</style>

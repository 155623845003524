<template>
  <div>
    <section class="w-100" key="new-encounter-form">
      <div v-loading="loading"  class="new-encounter-form" style="max-width: 900px">
        <div class="btn btn-secondary btn-small pull-right" @click="$emit('switch')">Previous Encounters</div>
        <h4 style="text-align: center;">Tell Us About Your Encounter!</h4>
        <hr>
        <div class="two-column">
          <div class="flex-1">
            <div class='enc-label'>Players In Party:</div>
            <input type='number' min='1' max='10' class="form-control" v-model="formData.playerCount">
          </div>

          <div class="flex-1">
            <div class='enc-label'>Average Player Level:</div>
            <input type='number' min='1' max='20' class="form-control" v-model="formData.playerLevel">
          </div>
        </div>

        <div style="position: relative">
          <div class='enc-label'>Encounter Location:</div>
          <input class="form-control" maxlength="100" v-model="formData.biome">
          <div class="btn btn-primary btn-small regen-btn" @click="randomLocation">Random</div>
        </div>
        <hr>

        <div class="btn btn-primary w-100" @click="generateSummaries">Get Some Ideas</div>
      </div>
    </section>
  </div>
</template>

<script>
// import axiosInstance from '../axiosConfig';
import locations from '../randomLocations'

export default {
  name: 'GetItems',

  data() {
    return {
      loading: false,
      formData: {
        playerLevel: 5,
        playerCount: 5,
        biome: 'A ancient forest destroyed by fire'
      }
    }
  },

  mounted() {
    this.randomLocation()
  },

  methods: {
    randomLocation() {
      this.formData.biome = locations[this.randomIntFromInterval(0,locations.length)]

    },
    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    },

    async generateSummaries() {
      this.loading = true
      this.$nextTick(async () => {
        await this.$store.dispatch('generateSummary', {
          playerCount: this.formData.playerCount,
          playerLevel: this.formData.playerLevel,
          biome: this.formData.biome
        })
      })
    }
  }
}
</script>

<style>
.enemies {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.enc-label {
  margin-top: 1rem;
  font-weight: bold;
}

.two-column {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.p-relative {
  position: relative;
}

.new-encounter-form {
  color: var(--bg-color);
  background-color: var(--card-color);
  border-radius: 20px;
  width: 95%;
  overflow: clip;
  padding: 2rem;
  box-shadow: 0 10px 34px -15px black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}



.btn.btn-primary:hover {
  background-color: var(--primary-focus) !important;
}


.btn.btn-secondary:hover {
  background-color: var(--secondary-focus) !important;
}



.flex-1 {
  flex: 1;
}

.form-control {
  height: 48px;
  background: #fff;
  color: #000;
  font-size: 16px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.encounter-detail-card {
  background-color: var(--card-color);
  color: var(--bg-color);
  border-radius: 20px;
  overflow: clip;
  box-shadow: 0 10px 34px -15px black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.padded-card{
  padding: 2rem;
}

hr {
  color: var(--secondary-focus) !important;
}

.regen-btn{
  position: absolute;
  right: 10px;
  top: 40px;
}
</style>

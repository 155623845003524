<template>
  <section class="w-100" >
    <div class="new-encounter-form center-text" style="max-width: 900px">
      <h4 class="secondary-text">Generating Your Encounter</h4>
      <i class="el-icon-loading"></i>

      <hr>
      <h6>
        This can take several minutes.  While you wait, why don't you think about an encounter in:
      </h6>
      <h6 :class="{ fade: faded, 'fadable': true }">
        {{ currentLocation }}
      </h6>
    </div>
  </section>
</template>

<script>

import randomLocations from "../randomLocations";

export default {
  name: 'LoadingScreen',

  data() {
    return {
      currentLocation: '',
      faded: false,
    }
  },

  mounted() {
    this.randomLocation()

  },

  methods: {
    randomLocation() {
      this.faded = true

      setTimeout(() => {
        this.currentLocation = randomLocations[this.randomIntFromInterval(0,randomLocations.length)]
        this.faded = false
        setTimeout(this.randomLocation, 5000)
      }, 1000)
    },
    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
  },
}
</script>

<style>
.center-text{
  text-align: center;
}
.fadable {
  transition: opacity 1s;
  font-weight: bold;
  margin: 20px;
}
.fade   {
  opacity: 0
}

.secondary-text{
  color: var(--secondary);
}
</style>

<template>
  <div v-loading="loading" key="encounter-table">
    <div class="encounter-table">
      <div class="encounter-table-wrapper">
        <div class="encounter-table-header">
          <div class="btn btn-secondary pull-right btn-small" @click="$emit('switch')">Generate New Encounter</div>
          <h4>Your Previous Encounters</h4>
          <hr>
        </div>


        <el-table class='padded-table' :data="encounters" style="width: 100%">
          <el-table-column
              label="Encounter">
            <template v-slot="scope">
              <div class="encounter-table-row">
                <h6>
                  <span v-if="scope.row.selectedSummary">
                    {{ scope.row.selectedSummary }} - {{ scope.row.location}}
                  </span>
                  <span v-else>
                    {{ scope.row.location}}
                  </span>
                </h6>
                <div class="row-label-wrapper">
                  <div class="row-label">
                    <span class="row-label-label">Players:</span>
                    {{ scope.row.playerCount}}
                  </div>

                  <div class="row-label">
                    <span class="row-label-label">Level:</span>
                    {{ scope.row.playerLevel}}
                  </div>

                  <div class="row-label">
                    <span class="row-label-label">Status:</span>
                    {{ scope.row.status }}
                  </div>
                </div>

              </div>
            </template>
          </el-table-column>

          <el-table-column
              width='100px'
              label="Actions">
            <template v-slot="scope">
              <a class="btn btn-primary btn-small" :href='"/#/encounters/" + scope.row.id'>View</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Dashboard',
  props: ['encounters'],


}
</script>

<style>
.encounter-table-wrapper {
  color: var(--bg-color);
  background-color: var(--card-color);
  border-radius: 20px;
  width: 100%;
  overflow: clip;
  padding: 2rem;
}



.adventure-image {
  width: 900px;
  height: 900px;
  position: relative;
}

.encounter-table {
  background-color: var(--card-color);
  color: var(--bg-color);
  width: 800px;
  border-radius: 20px;
  overflow: clip;
  box-shadow: 0 10px 34px -15px black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.encounter-table-header{
  font-size: small;
  color: var(--bg-color);

  .el-table{
    border-radius: 20px;
  }
}

.row-label-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row-label-label{
  font-weight: bold;
  color: var(--secondary);
}

.padded-table{
  border-radius: 20px;
  overflow: clip;
  box-shadow: 0 10px 34px -15px black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
